import React, { useState, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import NewsList from './NewsList';
import TickerSearch from './TickerSearch';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; // Import App.css
import api from './api'; // Adjust the path to where your api.js or axiosConfig.js is located

const queryClient = new QueryClient();

function App() {
  const [asset, setAsset] = useState('');
  const [noTrackedAssets, setNoTrackedAssets] = useState(false);

  useEffect(() => {
    const fetchTrackedAssets = async () => {
      try {
        const { data } = await api.get('/tracked-assets');
        if (data.length > 0) {
          setAsset(data[0]);
        } else {
          setNoTrackedAssets(true);
        }
      } catch (error) {
        console.error('Error fetching tracked assets:', error.message);
      }
    };
    fetchTrackedAssets();
  }, []);

  useEffect(() => {
    if (asset) {
      setNoTrackedAssets(false);
    }
  }, [asset]);

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <TickerSearch setAsset={setAsset} />
        {asset ? (
          <NewsList key={asset} asset={asset} />
        ) : noTrackedAssets ? (
          <div className="container mt-4">
            <div className="alert alert-info text-center" role="alert" style={{ fontSize: '24px' }}>
              Choose an asset to track
            </div>
          </div>
        ) : (
          <div className="container mt-4">Loading...</div>
        )}
      </div>
    </QueryClientProvider>
  );
}

export default App;
