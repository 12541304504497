import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Pagination.css'; // Make sure to import the custom CSS
import './NewsList.css'; // Import custom CSS for sorting indicators
import api from './api'; // Adjust the path to where your api.js or axiosConfig.js is located

// Fetch news function
const fetchNews = async (asset) => {
    const { data } = await api.get(`/news?asset=${asset}&timeframe=1m`);
    return data;
};

const NewsList = ({ asset }) => {
    const [timeframe, setTimeframe] = useState('1d');
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState('time_published');
    const [sortOrder, setSortOrder] = useState('desc');
    const [allNewsWithinMonth, setAllNewsWithinMonth] = useState([]);
    const [sentimentScores, setSentimentScores] = useState({
        '1h': 0,
        '1d': 0,
        '1w': 0,
        '1m': 0
    });

    const fetchAndStoreNews = async () => {
        try {
            const data = await fetchNews(asset);
            setAllNewsWithinMonth(data.allNewsWithinMonth);
            setSentimentScores(data.sentimentScores);
        } catch (error) {
            console.error("Error fetching news:", error.message);
        }
    };

    useEffect(() => {
        fetchAndStoreNews();
        const intervalId = setInterval(fetchAndStoreNews, 600000); // 600000ms = 10 minutes
        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [asset]);

    useEffect(() => {
        setPage(1); // Reset to page 1 whenever timeframe or asset changes
    }, [timeframe, asset]);

    const handlePageClick = (data) => {
        setPage(data.selected + 1);
    };

    const handleSort = (field) => {
        const order = sortBy === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortBy(field);
        setSortOrder(order);
    };

    const filterNewsByTimeframe = () => {
        const currentTime = new Date();
        const timeFrom = new Date(currentTime.getTime() - {
            '1h': 3600 * 1000,
            '1d': 24 * 3600 * 1000,
            '1w': 7 * 24 * 3600 * 1000,
            '1m': 30 * 24 * 3600 * 1000,
        }[timeframe]);
        return allNewsWithinMonth.filter(newsItem => new Date(newsItem.time_published) >= timeFrom);
    };

    const sortedNews = filterNewsByTimeframe().sort((a, b) => {
        const aValue = sortBy === 'relevance_score'
            ? a.ticker_sentiment.find(ts => ts.ticker === asset)?.relevance_score ?? 0
            : a[sortBy];

        const bValue = sortBy === 'relevance_score'
            ? b.ticker_sentiment.find(ts => ts.ticker === asset)?.relevance_score ?? 0
            : b[sortBy];

        if (sortOrder === 'asc') {
            return aValue > bValue ? 1 : -1;
        } else {
            return aValue < bValue ? 1 : -1;
        }
    });

    const formatScore = (score) => {
        const num = parseFloat(score);
        if (num > 0) {
            return `+${num.toFixed(4)}`;
        }
        return num.toFixed(4);
    };

    const itemsPerPage = 10;
    const pageCount = Math.ceil(sortedNews.length / itemsPerPage);
    const paginatedNews = sortedNews.slice((page - 1) * itemsPerPage, page * itemsPerPage);

    return (
        <div className="container">
            <div className="sentiment-bar row mb-3">
                <div className="col sentiment-change">Sentiment Change</div>
                <div className={`col sentiment-item ${sentimentScores['1h'] > 0 ? 'positive' : sentimentScores['1h'] < 0 ? 'negative' : ''}`}>
                    One hour: {formatScore(sentimentScores['1h'])}
                </div>
                <div className={`col sentiment-item ${sentimentScores['1d'] > 0 ? 'positive' : sentimentScores['1d'] < 0 ? 'negative' : ''}`}>
                    One day: {formatScore(sentimentScores['1d'])}
                </div>
                <div className={`col sentiment-item ${sentimentScores['1w'] > 0 ? 'positive' : sentimentScores['1w'] < 0 ? 'negative' : ''}`}>
                    One week: {formatScore(sentimentScores['1w'])}
                </div>
                <div className={`col sentiment-item ${sentimentScores['1m'] > 0 ? 'positive' : sentimentScores['1m'] < 0 ? 'negative' : ''}`}>
                    One month: {formatScore(sentimentScores['1m'])}
                </div>
            </div>

            <div className="row my-3">
                <div className="col">
                    <label>Timeframe:</label>
                    <select value={timeframe} onChange={(e) => setTimeframe(e.target.value)} className="form-select">
                        <option value="1h">Last hour</option>
                        <option value="1d">Last day</option>
                        <option value="1w">Last week</option>
                        <option value="1m">Last month</option>
                    </select>
                </div>
                <div className="col">
                    <label>Asset:</label>
                    <input
                        type="text"
                        value={asset}
                        readOnly
                        className="form-control"
                    />
                </div>
            </div>

            <table className="table table-striped">
                <thead>
                    <tr>
                        <th style={{ width: '30%' }}>Title</th>
                        <th style={{ width: '50%' }}>Summary</th>
                        <th style={{ width: '10%' }} onClick={() => handleSort('overall_sentiment_score')} className="sortable-column">
                            Sentiment Score
                            <span className={`sort-indicator ${sortOrder}`}></span>
                        </th>
                        <th style={{ width: '10%' }} onClick={() => handleSort('relevance_score')} className="sortable-column">
                            Relevance
                            <span className={`sort-indicator ${sortOrder}`}></span>
                        </th>
                        <th style={{ width: '10%' }}>Source</th>
                        <th style={{ width: '10%' }} onClick={() => handleSort('time_published')} className="sortable-column">
                            Time Published
                            <span className={`sort-indicator ${sortOrder}`}></span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedNews.map((item) => {
                        const tickerInfo = item.ticker_sentiment.find(ts => ts.ticker === asset);
                        const relevanceScore = tickerInfo ? parseFloat(tickerInfo.relevance_score) : 0;

                        return (
                            <tr key={item._id}>
                                <td><a href={item.url} target="_blank" rel="noopener noreferrer">{item.title}</a></td>
                                <td>{item.summary}</td>
                                <td style={{ color: item.overall_sentiment_score > 0 ? 'darkgreen' : item.overall_sentiment_score < 0 ? 'crimson' : 'black' }}>
                                    {formatScore(item.overall_sentiment_score)}
                                </td>
                                <td>{relevanceScore.toFixed(4)}</td>
                                <td><a href={`https://${item.source_domain}`} target="_blank" rel="noopener noreferrer">{item.source}</a></td>
                                <td>{new Date(item.time_published).toLocaleString()}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <div className="d-flex justify-content-center">
                <ReactPaginate
                    previousLabel="<"
                    nextLabel=">"
                    breakLabel="..."
                    breakClassName="break-me"
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName="pagination-container"
                    activeClassName="active"
                    previousClassName={page === 1 ? "disabled" : ""}
                    nextClassName={page === pageCount ? "disabled" : ""}
                    previousLinkClassName={page === 1 ? "disabled" : ""}
                    nextLinkClassName={page === pageCount ? "disabled" : ""}
                />
            </div>
            <div className="d-flex justify-content-center page-info">
                <span>Page {page} of {pageCount}</span>
            </div>
        </div>
    );
};

export default NewsList;
