import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import 'bootstrap/dist/css/bootstrap.min.css';
import './TickerSearch.css';
import Modal from './Modal';
import api from './api'; // Adjust the path to where your api.js or axiosConfig.js is located

// Function to fetch available tickers from the backend
const fetchAvailableTickers = async () => {
    try {
        const { data } = await api.get('/available-tickers');
        return data;
    } catch (error) {
        console.error('Error fetching available tickers:', error.message);
        throw error;
    }
};

// Function to fetch tracked assets from the backend
const fetchTrackedAssets = async () => {
    try {
        const { data } = await api.get('/tracked-assets');
        return data;
    } catch (error) {
        console.error('Error fetching tracked assets:', error.message);
        throw error;
    }
};

// Function to add a tracked asset to the backend
const addTrackedAsset = async (ticker) => {
    try {
        await api.post('/tracked-assets', { ticker });
    } catch (error) {
        console.error('Error adding tracked asset:', error.message);
        throw error;
    }
};

// Function to remove a tracked asset from the backend
const removeTrackedAsset = async (ticker) => {
    try {
        await api.delete(`/tracked-assets/${ticker}`);
    } catch (error) {
        console.error('Error removing tracked asset:', error.message);
        throw error;
    }
};

const TickerSearch = ({ setAsset }) => {
    const [searchInput, setSearchInput] = useState('');
    const [showModal, setShowModal] = useState(false);
    const queryClient = useQueryClient();

    // Use React Query to fetch available tickers
    const { data: availableTickers, isLoading: isLoadingTickers, error: tickersError } = useQuery('availableTickers', fetchAvailableTickers);

    // Use React Query to fetch tracked assets
    const { data: trackedAssets, isLoading: isLoadingTracked, error: trackedError, refetch } = useQuery('trackedAssets', fetchTrackedAssets, {
        onSuccess: (data) => {
            // If there are tracked assets and no search input, set the first tracked asset as the current asset
            if (data.length > 0 && !searchInput) {
                setAsset(data[0]);
            }
        }
    });

    // Mutation to add a tracked asset
    const addMutation = useMutation(addTrackedAsset, {
        onSuccess: () => {
            // Invalidate and refetch the tracked assets query
            queryClient.invalidateQueries('trackedAssets');
            refetch(); // Refetch tracked assets
        }
    });

    // Mutation to remove a tracked asset
    const removeMutation = useMutation(removeTrackedAsset, {
        onSuccess: () => {
            // Invalidate and refetch the tracked assets query
            queryClient.invalidateQueries('trackedAssets');
        }
    });

    // Handle search input change
    const handleSearchChange = (e) => {
        setSearchInput(e.target.value);
    };

    // Handle tracking an asset
    const handleTrack = (ticker) => {
        addMutation.mutate(ticker);
    };

    // Handle untracking an asset
    const handleUntrack = (ticker) => {
        removeMutation.mutate(ticker);
    };

    // Handle clicking on a tracked asset to set it as the current asset and close modal
    const handleAssetClick = (ticker) => {
        setAsset(ticker);
        setShowModal(false); // Close modal after asset is selected
    };

    // Remove duplicates from the array
    const removeDuplicates = (array) => [...new Set(array)];

    // Filter and prioritize available tickers based on search input
    const filteredTickers = availableTickers
        ?.filter(ticker => ticker.toLowerCase().includes(searchInput.toLowerCase()))
        .sort((a, b) => {
            const aIndex = a.toLowerCase().indexOf(searchInput.toLowerCase());
            const bIndex = b.toLowerCase().indexOf(searchInput.toLowerCase());

            if (aIndex === bIndex) {
                // Secondary sort by alphabetical order if both have the same index
                return a.localeCompare(b);
            }

            return aIndex - bIndex;
        });

    // Remove duplicates and already tracked assets from available tickers
    const uniqueFilteredTickers = removeDuplicates(filteredTickers?.filter(ticker => !trackedAssets?.includes(ticker)));

    // Remove duplicates from tracked assets
    const uniqueTrackedAssets = removeDuplicates(trackedAssets);

    // Display loading state
    if (isLoadingTickers || isLoadingTracked) {
        return <div>Loading...</div>;
    }

    // Display error state
    if (tickersError || trackedError) {
        return <div>Error loading data</div>;
    }

    return (
        <div className="container">
            <button className="select-asset-button" onClick={() => setShowModal(true)}>
                Select Asset
            </button>

            <Modal show={showModal} handleClose={() => setShowModal(false)}>
                <div className="row my-3">
                    <div className="col">
                        <label>Search and Track Assets:</label>
                        <input
                            type="text"
                            value={searchInput}
                            onChange={handleSearchChange}
                            className="form-control"
                            placeholder="Search tickers"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {searchInput && (
                            <>
                                <h5>Available Tickers</h5>
                                <div className="scrollable-list">
                                    <ul className="list-group">
                                        {uniqueFilteredTickers?.map((ticker) => (
                                            <li key={ticker} className="list-group-item d-flex justify-content-between align-items-center available-ticker-row">
                                                <span>{ticker}</span>
                                                <button
                                                    className="btn btn-primary btn-sm track-button"
                                                    onClick={() => handleTrack(ticker)}
                                                >
                                                    Track
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </>
                        )}
                        <h5>Tracked Assets</h5>
                        <div className="scrollable-list">
                            <ul className="list-group">
                                {uniqueTrackedAssets?.length > 0 && uniqueTrackedAssets?.map((ticker) => (
                                    <li key={ticker} className="list-group-item d-flex justify-content-between align-items-center tracked-ticker-row"
                                        onClick={(e) => {
                                            if (e.target.tagName !== 'BUTTON') handleAssetClick(ticker);
                                        }}>
                                        <span>{ticker}</span>
                                        <button
                                            className="btn btn-danger btn-sm track-button"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleUntrack(ticker);
                                            }}
                                        >
                                            Untrack
                                        </button>
                                    </li>
                                ))}
                                {uniqueTrackedAssets?.length === 0 && (
                                    <li className="list-group-item">No tracked assets</li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default TickerSearch;
